import { useState, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import { getRiotAssetsContext, getUrl } from "@outplayed/riot-assets";
import { Checkbox } from "@ugg/shared/components/common/Checkbox";
import { ReactComponent as BlueTower } from "@ugg/shared/assets/svg/post-match/objective-towers.svg";
import {
  SummonerMatch,
  BuildingType,
  TowerType,
  WardType,
  MonsterType,
  MonsterSubType,
} from "@ugg/shared/api/requests/summoner-profiles/single-match";
import { TeamData } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";

interface TimelineRowProps {
  index: number;
  event: SummonerMatch["historicalData"]["timelineData"][number];
  allPlayers: TeamData[];
  proTeam: 1 | 2;
  handleRowMouseStateEnter: (index: number) => void;
  handleRowMouseStateLeave: () => void;
  hoveredEvent: number | null;
}

const TimelineRow = (props: TimelineRowProps) => {
  const { index, event, allPlayers, proTeam, handleRowMouseStateEnter, handleRowMouseStateLeave, hoveredEvent } = props;

  const { getItemImg, getChampionImgFromSprite } = getRiotAssetsContext();
  const timestamp = event.timestamp;
  const timestampMin = Math.round(timestamp / 1000 / 60);
  const isHovered = hoveredEvent === index;

  const side =
    (proTeam === 1 && (event.pid <= 5 || (event.killerId !== null && event.killerId <= 5))) ||
    (proTeam === 2 && (event.pid >= 6 || (event.killerId !== null && event.killerId >= 6)))
      ? "pro"
      : "against";
  const killLabel =
    event?.eventType === "champion_kill" || event.eventType === "monster_kill"
      ? "killed"
      : event.eventType === "building_kill"
      ? "destroyed"
      : "placed";

  const summonerKiller = { riotUserName: event?.riotUserName, riotTagLine: event?.riotTagLine };
  const summonerVictim = { riotUserName: event?.victimRiotUserName, riotTagLine: event?.victimRiotTagLine };

  const summoner1Ref = useRef<HTMLSpanElement>(null);
  const summoner2Ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const summoner1 = summoner1Ref.current;
    const summoner2 = summoner2Ref.current;

    if (summoner1?.offsetWidth && summoner1.offsetWidth > 70) {
      summoner1.textContent = `${summonerKiller.riotUserName.slice(0, 10)}...`;
    }
    if (summoner2?.offsetWidth && summoner2.offsetWidth > 70) {
      summoner2.textContent = `${summonerVictim.riotUserName.slice(0, 10)}...`;
    }
  });

  const getWard = (ward: WardType) => {
    const obj: { [key in WardType]?: { name: string; img: string } } = {
      yellow_trinket: { name: "Stealth Ward", img: getItemImg(3340) },
      blue_trinket: { name: "Farsight Ward", img: getItemImg(3363) },
      control_ward: { name: "Control Ward", img: getItemImg(2055) },
      sight_ward: { name: "Sight Ward", img: getItemImg(3340) },
    };
    return obj[ward] || { name: "Sight Ward", img: getItemImg(3340) };
  };

  const getMonster = (monster: MonsterType | MonsterSubType | null) => {
    const obj: { [key in MonsterType | MonsterSubType]?: { name: string; img: string } } = {
      horde: { name: "Voidgrub", img: getUrl("lol/icons/voidgrub.jpg") },
      baron_nashor: { name: "Baron Nashor", img: getUrl("lol/icons/baron-nashor.jpg") },
      air_dragon: { name: "Cloud Drake", img: getUrl("lol/icons/cloud-drake.jpg") },
      elder_dragon: { name: "Elder Dragon", img: getUrl("lol/icons/elder-dragon.jpg") },
      hextech_dragon: { name: "Hextech Drake", img: getUrl("lol/icons/hextech-drake.jpg") },
      fire_dragon: { name: "Infernal Drake", img: getUrl("lol/icons/infernal-drake.jpg") },
      earth_dragon: { name: "Mountain Drake", img: getUrl("lol/icons/mountain-drake.jpg") },
      water_dragon: { name: "Ocean Drake", img: getUrl("lol/icons/ocean-drake.jpg") },
      chemtech_dragon: { name: "Chemtech Drake", img: getUrl("lol/icons/chemtech-dragon.jpg") },
      riftherald: { name: "Rift Herald", img: getUrl("lol/icons/rift-herald.jpg") },
    };
    return monster ? obj[monster] : undefined;
  };

  const getBuilding = (building: BuildingType | TowerType | null) => {
    const buildings: { [key in BuildingType | TowerType]?: { name: string } } = {
      outer_turret: { name: "Outer Turret" },
      inner_turret: { name: "Inner Turret" },
      base_turret: { name: "Base Turret" },
      inhibitor_building: { name: "Inhibitor" },
      nexus_turret: { name: "Nexus Turret" },
    };
    return building ? buildings[building] : undefined;
  };

  const ward = getWard(event.wardType);
  const monster = getMonster(event.monsterType === "dragon" ? event.monsterSubtype : event.monsterType);
  const building = getBuilding(event.buildingType === "tower_building" ? event.towerType : event.buildingType);

  function findChampionId({ riotUserName, riotTagLine }: { riotUserName: string; riotTagLine: string }) {
    for (const summoner in allPlayers) {
      if (allPlayers[summoner].riotUserName === riotUserName && allPlayers[summoner].riotTagLine === riotTagLine) {
        return allPlayers[summoner].championId;
      }
    }
  }

  const iconClassNames = "flex-none flex items-center justify-center w-[24px] h-[24px] bg-purple-200 rounded-[2px] mr-[6px]";
  const championImgClassNames =
    "flex-none flex items-center justify-center w-[24px] h-[24px] bg-purple-200 rounded-[2px] overflow-hidden";

  return (
    <div
      className="flex-none flex items-center w-full h-[38px] text-lavender-50 text-[11px]"
      onMouseEnter={() => handleRowMouseStateEnter(index)}
      onMouseLeave={() => handleRowMouseStateLeave()}
      data-event={event.eventType + "_" + index}
    >
      <div className="flex items-center justify-end w-[45px] mr-[12px]">{timestampMin} min</div>
      <div
        className={classNames(`_event-info flex items-center w-[80%] h-full rounded-[2px]`, {
          "bg-[linear-gradient(90deg,rgba(50,115,250,0.2)_0%,rgba(50,115,250,0)_100%)]": side === "pro",
          "bg-[linear-gradient(90deg,rgba(255,78,80,0.2)_0%,rgba(255,78,80,0)_100%)]": side === "against",
          "bg-[linear-gradient(90deg,rgba(50,115,250,0.5)_0%,rgba(50,115,250,0)_100%)]": side === "pro" && isHovered,
          "bg-[linear-gradient(90deg,rgba(255,78,80,0.5)_0%,rgba(255,78,80,0)_100%)]": side === "against" && isHovered,
        })}
      >
        <div className="flex items-center w-full h-full max-w-fit">
          <div className="w-[24px] h-[24px] ml-[8px] mr-[6px]">
            <div className={classNames(championImgClassNames)}>
              {getChampionImgFromSprite(findChampionId(summonerKiller), { size: 26 })}
            </div>
          </div>
          <div>
            <span ref={summoner1Ref}>{summonerKiller?.riotUserName}</span>
            <span
              className={classNames("whitespace-pre", {
                "text-accent-blue-400": side === "pro",
                "text-accent-orange-500": side === "against",
              })}
            >
              {" "}
              {killLabel}
              {"  "}
            </span>
          </div>
        </div>
        <div className="flex items-center">
          {event.eventType === "champion_kill" && (
            <>
              <div className="w-[24px] h-[24px] mr-[6px]">
                <div className={classNames(championImgClassNames)}>
                  {" "}
                  {getChampionImgFromSprite(findChampionId(summonerVictim), { size: 26 })}{" "}
                </div>
              </div>
              <span ref={summoner2Ref}>{`${summonerVictim?.riotUserName}`}</span>
            </>
          )}
          {event.eventType === "ward_placed" && ward && (
            <>
              <div className={classNames(iconClassNames)}>
                <img
                  className="w-full"
                  src={ward.img}
                  alt={ward.name}
                  onError={(e) => (e.currentTarget.style.display = "none")}
                />
              </div>
              <span>{`${ward.name}`}</span>
            </>
          )}
          {event.eventType === "ward_kill" && ward && (
            <>
              <div className={iconClassNames}>
                <img
                  className="w-full"
                  src={ward.img}
                  alt={ward.name}
                  onError={(e) => (e.currentTarget.style.display = "none")}
                />
              </div>
              <span>{`${ward.name}`}</span>
            </>
          )}
          {event.eventType === "monster_kill" && monster && (
            <>
              <div className={iconClassNames}>
                <img
                  className="w-full"
                  src={monster.img}
                  alt={monster.name}
                  onError={(e) => (e.currentTarget.style.display = "none")}
                />
              </div>
              <span>{monster.name}</span>
            </>
          )}
          {event.eventType === "building_kill" && building && (
            <>
              <div className={iconClassNames}>
                <BlueTower
                  className={classNames("h-[16px]", {
                    "[&_path]:fill-accent-blue-400": side === "against",
                    "[&_path]:fill-[red]": side === "pro",
                  })}
                />
              </div>
              <span>{building.name}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

interface BlankMapProps {
  matchEvent: SummonerMatch["historicalData"]["timelineData"][number];
  index: number;
  hoveredEvent: number | null;
  proTeam: 1 | 2;
  containerWidth: number;
}

const BlankMap = (props: BlankMapProps) => {
  const { matchEvent, index, hoveredEvent, proTeam, containerWidth } = props;
  const isHovered = index === hoveredEvent;
  const side =
    (proTeam === 1 && (matchEvent.pid <= 5 || (matchEvent.killerId && matchEvent.killerId <= 5))) ||
    (proTeam === 2 && (matchEvent.pid >= 6 || (matchEvent.killerId && matchEvent.killerId >= 6)))
      ? "pro"
      : "against";

  //Add the sides to the calculation, right now there's 34px between the border and
  // the side of the map. So 34 x 2 is subtracted AFTER percentage

  //Width between map and border
  //-4 to account for circle width and map border
  const mapWidth = 17 - 4;
  const mapWidthBoth = 2 * mapWidth;
  const size = containerWidth - mapWidthBoth;

  //Summoners rift is (0,0) in the bottom left to (16000,16000)
  let posX;
  let posY;
  if (matchEvent.position) {
    const { x, y } = matchEvent.position[0];
    posX = x / (16000 - mapWidth * 2);
    posY = (y / 16000) * 100;
  }

  const posStyle = {
    bottom: posY + "%",
    left: `calc(${size}px * ${posX} + ${mapWidth}px)`,
  };

  return (
    <div
      style={{ ...posStyle }}
      className={classNames("absolute flex items-center justify-center rounded-[50%] w-[16px] h-[16px]", {
        "z-[1]": isHovered,
        "bg-accent-blue-400": isHovered && side === "pro",
        "bg-accent-red-500": isHovered && side === "against",
      })}
    >
      <div
        className={classNames("rounded-[50%]", {
          "w-[12px] h-[12px]": !isHovered,
          "bg-[#1e2b5e]": !isHovered && side === "pro",
          "bg-[#47243c]": !isHovered && side === "against",
          "w-[14px] h-[14px] border-[1px] border-[rgba(0,0,0,0.9)] z-[1]": isHovered,
          "bg-accent-blue-400": isHovered && side === "pro",
          "bg-accent-red-500": isHovered && side === "against",
        })}
      />
    </div>
  );
};

interface TimelineProps {
  data: SummonerMatch;
}
export function Timeline(props: TimelineProps) {
  const { data } = props;
  const allPlayers = [...data.matchSummary.teamA, ...data.matchSummary.teamB];
  const mainSummoner = { riotUserName: data.playerInfo.riotUserName, riotTagLine: data.playerInfo.riotTagLine };
  const dotsContainerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const [includeKills, setIncludeKills] = useState(true);
  const [includeObjectives, setIncludeObjectives] = useState(true);
  const [includeVisions, setIncludeVisions] = useState(false);

  const [hoveredEvent, setHoveredEvent] = useState<number | null>(null);

  function findSummonerTeam(mainSummoner: { riotUserName: string; riotTagLine: string }) {
    const firstHalf = allPlayers.slice(0, 5);
    if (
      firstHalf.find(
        (player) => player.riotUserName === mainSummoner.riotUserName && player.riotTagLine === mainSummoner.riotTagLine,
      )
    ) {
      return 1;
    }
    return 2;
  }

  function handleRowMouseStateEnter(index: number) {
    setHoveredEvent(index);
  }

  function handleRowMouseStateLeave() {
    setHoveredEvent(null);
  }

  const filteredData = useMemo(() => {
    // Make the three arrays of different data, kills/objectives/vision
    let killData: SummonerMatch["historicalData"]["timelineData"] = [];
    let objectiveData: SummonerMatch["historicalData"]["timelineData"] = [];
    let visionData: SummonerMatch["historicalData"]["timelineData"] = [];

    //Filter data by type
    for (let eventOccurance of data.historicalData.timelineData) {
      if (["champion_kill"].includes(eventOccurance["eventType"])) {
        killData.push(eventOccurance);
      } else if (["building_kill", "turret_plate_destroyed", "monster_kill"].includes(eventOccurance["eventType"])) {
        objectiveData.push(eventOccurance);
      } else if (["ward_placed", "ward_kill"].includes(eventOccurance["eventType"])) {
        visionData.push(eventOccurance);
      }
    }

    const tempData = [];
    includeKills && tempData.push(...killData);
    includeObjectives && tempData.push(...objectiveData);
    includeVisions && tempData.push(...visionData);
    const sortedData = [...tempData].sort((a, b) => a.timestamp - b.timestamp);

    return sortedData;
  }, [data.historicalData.timelineData, includeKills, includeObjectives, includeVisions]);

  useEffect(() => {
    if (dotsContainerRef.current) {
      const containerWidth = dotsContainerRef.current.offsetWidth;
      setContainerWidth(containerWidth);
    }
  }, [dotsContainerRef?.current?.offsetWidth]);

  return (
    <div>
      <div className="flex items-center justify-center gap-[24px] w-full h-[52px] bg-purple-100 border-b-[1px] border-purple-200">
        <div className="flex items-center">
          <Checkbox
            checkboxId="kills"
            isChecked={includeKills}
            onChange={(e) => setIncludeKills(e.target.checked)}
            checkboxWidth={16}
            checkboxBorderRadius={2}
          />
          <label htmlFor={"kills"} className="ml-[7px] text-lavender-50 text-[14px] cursor-pointer">
            Kills
          </label>
        </div>
        <div className="flex items-center">
          <Checkbox
            checkboxId="objectives"
            isChecked={includeObjectives}
            onChange={(e) => setIncludeObjectives(e.target.checked)}
            checkboxWidth={16}
            checkboxBorderRadius={2}
          />
          <label htmlFor={"objectives"} className="ml-[7px] text-lavender-50 text-[14px] cursor-pointer">
            Objectives
          </label>
        </div>
        <div className="flex items-center">
          <Checkbox
            checkboxId="vision"
            isChecked={includeVisions}
            onChange={(e) => setIncludeVisions(e.target.checked)}
            checkboxWidth={16}
            checkboxBorderRadius={2}
          />
          <label htmlFor={"vision"} className="ml-[7px] text-lavender-50 text-[14px] cursor-pointer">
            Vision
          </label>
        </div>
      </div>
      <div className="h-[282px] w-full flex px-[7px] py-[18px]">
        <div className="flex flex-col gap-y-[2px] h-full w-[57%] overflow-auto">
          {filteredData.map((matchEvent, index) => {
            return (
              <TimelineRow
                key={index}
                index={index}
                event={matchEvent}
                allPlayers={allPlayers}
                proTeam={findSummonerTeam(mainSummoner)}
                handleRowMouseStateEnter={handleRowMouseStateEnter}
                handleRowMouseStateLeave={handleRowMouseStateLeave}
                hoveredEvent={hoveredEvent}
              />
            );
          })}
        </div>
        <div className="h-full w-[43%]">
          <div
            ref={dotsContainerRef}
            className="h-full w-full relative bg-[url('https://static.bigbrain.gg/assets/lol/backgrounds/map-one.jpg')] bg-no-repeat bg-center"
          >
            {filteredData.map((matchEvent, index) => {
              return (
                matchEvent?.position && (
                  <BlankMap
                    key={index}
                    matchEvent={matchEvent}
                    index={index}
                    hoveredEvent={hoveredEvent}
                    proTeam={findSummonerTeam(mainSummoner)}
                    containerWidth={containerWidth}
                  />
                )
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
