import { RegionS } from "@ugg/shared/utils/region-helpers";

export const ALLSTAR_PUBLIC_API_KEY = "25f05c4b-ebd8-45cb-bd13-7f679a975646";
export const ALLSTAR_AUTH_HEADERS = {
  "X-API-Key": ALLSTAR_PUBLIC_API_KEY,
};

// For most GET requests
export function getAllstarURI() {
  return "https://prt.allstar.gg";
}

// For POST/server requests
export function getProxyAllstarURI() {
  const devURI = "https://d.u.gg/allstar-api";
  const prodURI = "https://u.gg/allstar-api";

  // App
  if (process.env.PLATFORM === "app") {
    return process.env.APP_BUILD_TYPE !== "build_prod_win" ? devURI : prodURI;
  }

  // Site
  return process.env.BUILD_TYPE !== "prod" ? devURI : prodURI;
}

export function skipAllstar(region: string) {
  // return false; // Allow all regions;

  if (!region) {
    return true;
  }

  const whitelist = [RegionS.NA, RegionS.EUW, RegionS.EUN];

  return !whitelist.includes(region as RegionS);
}

export function getAllstarClipOpenLog(
  location: string,
  platform: "app" | "site",
  user: { riotUserName: string; riotTagLine: string; region: string },
) {
  return {
    message: "Allstar clip open",
    context: {
      location,
      platform,
      meta: { ...user },
    },
  };
}
