import { Link } from "react-router-dom";
import { TooltipContainer } from "@outplayed/tooltips";
import { useUserState } from "@ugg/shared/api/requests/accounts/user-state";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import { ReactComponent as BoostIcon } from "@ugg/shared/assets/svg/boost-icon.svg";
import { ReactComponent as Verified } from "@ugg/shared/assets/svg/verified.svg";
import { ReactComponent as Unclaimed } from "@ugg/shared/assets/svg/unclaimed.svg";

interface MemberStatusProps {
  status: string;
  riotUserName: string;
  riotTagLine: string;
}

export function MemberStatus({ status, riotUserName, riotTagLine }: MemberStatusProps) {
  const { data } = useUserState();
  const savedSummoner = data?.getState?.lolSettings?.summoners?.[0];

  if (status == "premium") {
    return (
      <TooltipContainer tooltipInfo={`BOOST user account`}>
        <div>
          <BoostIcon className="w-[28px] max-xs:w-[24px]" />
        </div>
      </TooltipContainer>
    );
  } else if (status == "verified") {
    return (
      <TooltipContainer tooltipInfo={`Claimed Account`}>
        <div>
          <Verified className="w-[32px] max-xs:w-[24px]" />
        </div>
      </TooltipContainer>
    );
  } else {
    const redirectToVerification = savedSummoner?.riotUserName === riotUserName && savedSummoner?.riotTagLine === riotTagLine;
    const redirectToUpdateSummoner =
      data && (savedSummoner?.riotUserName !== riotUserName || savedSummoner?.riotTagLine !== riotTagLine);

    let url = "";
    if (!data) {
      url = AppRoutes.SIGNUP + "?redirect_url=/verification";
    } else if (redirectToVerification) {
      url = AppRoutes.VERIFICATION;
    } else if (redirectToUpdateSummoner) {
      url = AppRoutes.SETTINGS_ACCOUNT;
    }

    return (
      <TooltipContainer tooltipInfo={`Unclaimed Account`}>
        <Link to={""} className="flex items-center justify-center">
          <Unclaimed className="w-[32px] max-xs:w-[24px]" />
        </Link>
      </TooltipContainer>
    );
  }
}
