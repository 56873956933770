import React from "react";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { getOverview } from "@ugg/shared/api/data-parser/champions/overview";
import { useChampionOverview } from "@ugg/shared/api/requests/champions/overview";
import { usePrimaryRoles } from "@ugg/shared/api/requests/champions/primary-roles";
import Skills from "@ugg/shared/components/Champions/pages/Overview/Skills";
import Items from "@ugg/shared/components/Champions/pages/Overview/Items";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

import { normalizeRole, numToRoleMapping } from "@ugg/shared/utils/role-helpers";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { RankS } from "@ugg/shared/utils/rank-helpers";
import { RegionS } from "@ugg/shared/utils/region-helpers";

const LiveGameRecommendedBuild = (props) => {
  const { getChampionImg, getChampionName } = getRiotAssetsContext();
  const context = useSummonerProfileContext();
  const { realRiotUserName, realRiotTagLine } = context;
  const { data } = props;
  const { teamA, teamB, gameType } = data || {};

  const currentPlayer = [...(teamA || []), ...(teamB || [])].find((player) => {
    return player.riotUserName === realRiotUserName && player.riotTagLine === realRiotTagLine;
  });
  const { championId, currentRole } = currentPlayer || {};

  const { data: primaryRoles, loading: fetchingPrimaryRoles, error: errorPrimaryRoles } = usePrimaryRoles({ skip: currentRole });

  let buildRole = normalizeRole(currentRole);
  if (primaryRoles && primaryRoles[championId]) {
    buildRole = numToRoleMapping(primaryRoles[championId][0]);
  }

  const defaultParams = {
    queueType: QueueTypeS.RANKED_SOLO,
    rank: RankS.EMERALD_PLUS,
    region: RegionS.WORLD,
    role: normalizeRole(buildRole),
  };
  const aramParams = {
    queueType: QueueTypeS.ARAM,
    region: RegionS.WORLD,
  };
  const urfParams = {
    queueType: QueueTypeS.PICK_URF,
    region: RegionS.WORLD,
  };
  const nexusBlitzParams = {
    queueType: QueueTypeS.NEXUS_BLITZ,
    region: RegionS.WORLD,
    role: normalizeRole(buildRole),
  };

  const forcedParams =
    gameType === QueueTypeS.NEXUS_BLITZ
      ? nexusBlitzParams
      : gameType === QueueTypeS.ARAM
      ? aramParams
      : gameType === QueueTypeS.PICK_URF || gameType === QueueTypeS.ARURF
      ? urfParams
      : defaultParams;

  const {
    data: championOverview,
    loading: fetchingChampionOverview,
    error: errorChampionOverview,
  } = useChampionOverview(championId, { forcedParams: forcedParams, skip: fetchingPrimaryRoles });

  const loading = fetchingChampionOverview || fetchingPrimaryRoles;
  const error = errorChampionOverview || errorPrimaryRoles;
  if (!championOverview || loading || error) {
    return null;
  }

  const filteredChampionOverview = getOverview(championOverview, forcedParams);
  const { rec_starting_items, rec_core_items, rec_skills, rec_skill_path, item_options_1, item_options_2, item_options_3 } =
    filteredChampionOverview || {};

  if (
    !rec_starting_items ||
    !rec_core_items ||
    !rec_skills ||
    !rec_skill_path
    // !item_options_1 ||
    // !item_options_2 ||
    // !item_options_3
  ) {
    return null;
  }

  return (
    <div className="mt-[24px]">
      <div className="flex items-center">
        <img className="rounded-[50%] w-[24px] h-[24px] mr-[8px]" src={getChampionImg(championId)} />
        <div className="text-white text-[16px] font-bold">{getChampionName(championId)} Recommended Build</div>
      </div>
      <div className="champion-recommended-build">
        <Skills championId={championId} skillPriority={rec_skills} skillPath={rec_skill_path} />
        <Items
          startingItems={rec_starting_items}
          coreItems={rec_core_items}
          items1={item_options_1 || []}
          items2={item_options_2 || []}
          items3={item_options_3 || []}
        />
      </div>
    </div>
  );
};

export default LiveGameRecommendedBuild;
