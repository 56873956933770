import { window } from "global";
import React, { useState, useEffect, useMemo, useRef } from "react";
import classnames from "classnames";
import FilterModal from "../FilterModal";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { ReactComponent as IconX } from "@ugg/shared/assets/svg/x.svg";
import { ReactComponent as TriangleArrowDown } from "@ugg/shared/assets/svg/triangle-arrow-down.svg";
import { ReactComponent as Search } from "@ugg/shared/assets/svg/search-icon.svg";

interface ChampionProps {
  className?: string;
  value: string;
  onFilterChange: (type: string, option: { value: string } | null) => void;
  filterKey?: string;
  hideDropdownIndicator?: boolean;
  customButton?: React.ReactNode;
  isMobile?: boolean;
  fontSize?: number;
  disableAllChampsOption?: boolean;
  disableClearButton?: boolean;
  defaultSearch?: boolean;
}

export default function Champion(props: ChampionProps) {
  const { getChampionImg, getChampionName, useChampionMini } = getRiotAssetsContext();
  const {
    className,
    value,
    onFilterChange,
    filterKey = "champion",
    hideDropdownIndicator,
    customButton,
    isMobile,
    fontSize,
    disableAllChampsOption = false,
    disableClearButton = false,
    defaultSearch,
  } = props;

  const filterRef = useRef<HTMLDivElement>(null);
  const [inputActive, setInputActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [highlightedItem, setHighlightedItem] = useState(0);
  const { data: championMini } = useChampionMini({ ssr: true });

  const { allChampions, mobileOptions } = useMemo(() => {
    const allChampions: Array<{ key: string; name: string }> = ((championMini && Object.values(championMini)) || []).sort(
      (a, b) => a.name.localeCompare(b.name),
    );

    const mobileOptions = allChampions.map((champion) => ({
      label: champion.name,
      value: champion.key,
    }));
    !disableAllChampsOption &&
      allChampions.unshift({
        key: "all",
        name: "All Champions",
      });
    !disableAllChampsOption &&
      mobileOptions.unshift({
        label: "All Champions",
        value: "all",
      });

    return { allChampions, mobileOptions };
  }, [championMini]);

  const resetFilter = () => {
    setInputActive(false);
    setInputValue("");
    setHighlightedItem(0);
  };

  useEffect(() => {
    if (window && filterRef.current && inputActive) {
      const outsideClick = (e: MouseEvent) => {
        if (filterRef.current && e.target !== null && !filterRef.current.contains(e.target as HTMLElement)) {
          resetFilter();
        }
      };
      window.addEventListener("click", outsideClick);

      return () => window.removeEventListener("click", outsideClick);
    }
  }, [inputActive]);

  useEffect(() => {
    setHighlightedItem(0);
  }, [inputValue]);

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    !inputActive && setInputActive(true);
  };

  const onFilterSelect = (champion: (typeof championList)[number]) => {
    onFilterChange(filterKey, { value: champion.key });
    resetFilter();
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const championList = useMemo(() => {
    if (!inputValue.trim()) return allChampions;

    return allChampions
      .filter((champion) => {
        const normalizedChampionName = champion.name.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
        const normalizedInput = inputValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
        return normalizedChampionName.startsWith(normalizedInput);
      })
      .slice(0, 8);
  }, [inputValue]);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      // enter
      onFilterSelect(championList[highlightedItem]);
    } else if (e.key === "ArrowUp") {
      // up
      e.preventDefault();
      setHighlightedItem(Math.abs(championList.length + highlightedItem - 1) % championList.length);
    } else if (e.key === "ArrowDown") {
      // down
      e.preventDefault();
      setHighlightedItem(Math.abs(championList.length + highlightedItem + 1) % championList.length);
    }
  };

  const championId = value;
  const filterClassName = classnames("filter-select filter-select_champion", className);

  const FilterComponent = (
    <React.Fragment>
      {
        // Need this because clicks don't trigger on parent <div>
        isMobile && <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}></div>
      }
      <div ref={filterRef} className={filterClassName} style={{ fontSize }}>
        <div className="champion-control" onClick={onClick}>
          {/*<span className="vs">vs.</span>*/}
          <div className="champion-label">
            {defaultSearch ? (
              <div className="champion">
                {championId ? (
                  <React.Fragment>
                    <div className="champion-image">
                      <img src={getChampionImg(championId)} />
                    </div>
                    <div className="champion-name">{getChampionName(championId)}</div>
                  </React.Fragment>
                ) : (
                  <input
                    style={{ fontSize }}
                    placeholder="Search champion"
                    value={inputValue}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                  />
                )}
              </div>
            ) : inputActive ? (
              <input
                style={{ fontSize }}
                placeholder="Search champion"
                value={inputValue}
                onChange={onChange}
                onKeyDown={onKeyDown}
                autoFocus
              />
            ) : (
              <div className="champion">
                {championId ? (
                  <React.Fragment>
                    <div className="champion-image">
                      <img src={getChampionImg(championId)} />
                    </div>
                    <div className="champion-name">{getChampionName(championId)}</div>
                  </React.Fragment>
                ) : (
                  <span>All Champions</span>
                )}
              </div>
            )}
          </div>
          {!inputActive && !hideDropdownIndicator && defaultSearch ? (
            <Search className="custom-indicator_search" />
          ) : (
            <TriangleArrowDown className="custom-indicator" />
          )}
          {!isMobile && championId && !inputActive && !disableClearButton && (
            <div
              className="clear-champion"
              onClick={(e) => {
                e.stopPropagation();
                onFilterChange(filterKey, { value: "all" });
              }}
            >
              <IconX className="x-icon" />
            </div>
          )}
        </div>
        {inputActive && championList.length > 0 && (
          <div className="champion-menu">
            {championList.map((champion, index) => {
              const className = classnames("champion-menu_item", { highlighted: index === highlightedItem });
              return (
                <div
                  key={champion.key}
                  className={className}
                  onMouseEnter={() => setHighlightedItem(index)}
                  onClick={() => onFilterSelect(champion)}
                >
                  {champion.key && <img src={getChampionImg(champion.key)} />}
                  <div className="champion-name">{champion.name}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </React.Fragment>
  );

  return (
    <div className="filter-select-wrapper">
      {isMobile ? (
        <FilterModal
          button={customButton || FilterComponent}
          title={"Change Champion"}
          group={"champion"}
          options={mobileOptions}
          curValue={{
            label: getChampionName(championId),
            value: championId,
          }}
          onApply={(selected) => onFilterChange(filterKey, selected)}
        />
      ) : (
        FilterComponent
      )}
    </div>
  );
}
