import { useRef } from "react";
import classNames from "classnames";
import DefaultSelect from "../DefaultSelect";
import { TooltipContainer } from "@outplayed/tooltips";
import { useTranslation } from "react-i18next";
import { FILTER_IS_NOT_DEFAULT_CLASSNAME } from "../FilterManager";
import { FilterSelect, FilterOption } from "@ugg/shared/interfaces/filter-manager.interface";

interface SeasonProps extends FilterSelect {
  className?: string;
  options: FilterOption[];
  disableTooltip?: boolean;
  isMobile?: boolean;
}

export default function Season(props: SeasonProps) {
  const tooltipRef = useRef<{ forceClose: Function }>({ forceClose: () => {} });
  const { t } = useTranslation();
  const { className, options, filters, isDefault, onFilterChange, isMobile, disableTooltip } = props;

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Season")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          title={t("Change Season")}
          isMobile={isMobile}
          className={classNames("filter-select", {
            [FILTER_IS_NOT_DEFAULT_CLASSNAME]: typeof isDefault === "boolean" ? !isDefault : !isDefault?.("season"),
          })}
          options={options}
          onChange={(selected) => {
            onFilterChange("season", selected);
          }}
          value={filters.season}
          isSearchable={false}
          width={120}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
}
